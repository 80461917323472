function Timer(callback, delay) {
    var args = arguments,
        self = this,
        timer, start;

    this.clear = function () {
        clearTimeout(timer);
    };

    this.pause = function () {
        this.clear();
        delay -= new Date() - start;
    };

    this.resume = function () {
        start = new Date();
        timer = setTimeout(function () {
            callback.apply(self, Array.prototype.slice.call(args, 2, args.length));
        }, delay);
    };

    this.resume();
}

function slider(list, time, anim_time, left, right, dot, pause_btn, play_btn) {

    var obj = this;
    this.dot=dot;
    this.dom_list = list;
    this.dom_left = left;
    this.dom_right = right;
    this.time=time;
    this.play = true;
    this.anim_time = anim_time;
    this.act = 0;
    this.slides_count = 0;
    this.anim_block = 0;
    this.time_timer;

    this.init = function() {
        obj.ustaw_slide();
        obj.arrow_click();
        obj.dot_click();
        obj.pauseAndPlayEvents();
    };

    this.arrow_click = function() {
        if(obj.dom_left) {
            $(obj.dom_left).click(function(){obj.slajd_change(0);});
        }
        if(obj.dom_right) {
            $(obj.dom_right).click(function(){obj.slajd_change(1);});
        }
    };

    this.dot_click = function () {
        if(obj.dot){
            $(obj.dot).eq(0).addClass("on");

            $(obj.dot).click(function () {
                var neww = $(this).index();
                if(obj.anim_block==0 && neww!=obj.act) {
                    obj.slajd_timer();
                    obj.anim_block = 1;

                    obj.animSlajd(neww);
                }
            })
        }
    };

    this.pauseAndPlayEvents = function() {
        if(play_btn!=undefined&&play_btn&&pause_btn!=undefined&&pause_btn) {
            $(pause_btn).show();
            $(play_btn).hide();

            $(pause_btn).click(function(){
                obj.play = false;
                obj.time_timer.pause();
                $(pause_btn).hide();
                $(play_btn).show();
            });

            $(play_btn).click(function(){
                obj.play = true;
                obj.time_timer.resume();
                $(pause_btn).show();
                $(play_btn).hide();
            });
        }
    }

    this.ustaw_slide = function() {
        obj.slides_count = $(obj.dom_list).length;
        obj.slajd_timer();
    };

    this.slajd_timer = function() {
        if (obj.time_timer !== undefined) {
            obj.time_timer.clear();
        }
        // clearTimeout(obj.time_timer);
        obj.time_timer = new Timer(function(){
            if(obj.play) {
                obj.slajd_change(0);
            }
            else {
                obj.slajd_timer();
            }
        }, obj.time);
        // obj.time_timer = setTimeout(function(){
        //     if(obj.play) {
        //         obj.slajd_change(0);
        //     }
        //     else {
        //         obj.slajd_timer();
        //     }
        // },obj.time);
    };

    this.slajd_change = function(type){
        if(obj.anim_block==0) {
            obj.slajd_timer();
            obj.anim_block = 1;
            if(type==0) {
                if(obj.act==(obj.slides_count-1)) {
                    var neww = 0;
                }
                else {
                    var neww = obj.act+1;
                }
            }
            else if(type==1) {
                if(obj.act==0) {
                    var neww = obj.slides_count-1;
                }
                else {
                    var neww = obj.act-1;
                }
            }
            obj.animSlajd(neww);
        }
    };

    this.animSlajd= function (neww) {
        $(obj.dot).eq(obj.act).removeClass("on");
        $(obj.dot).eq(neww).addClass('on');
        $(obj.dom_list).eq(obj.act).fadeOut(obj.anim_time);
        $(obj.dom_list).eq(neww).fadeIn(obj.anim_time, function(){
            obj.act = neww;
            obj.anim_block = 0;

        });
    };

    obj.init();
}
