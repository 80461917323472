function myAjaxList(key, search_fields_classes, history_api_on, config, active_page,pages_count, updateMomentally) {
    let obj = this;

    this.key = key;
    this.search_fields_classes = JSON.parse(search_fields_classes);
    this.active_page = active_page;
    this.pages_count = pages_count;
    this.config = JSON.parse(config);

    this.refresh_timeout = null;

    this.init = function() {
        obj.connectSearchFields();
        obj.paginationEngine();
        obj.showArrowEngine();
    };

    this.connectSearchFields = function() {
        for(let class_name in obj.search_fields_classes) {
            let default_value = obj.search_fields_classes[class_name];
            $("."+class_name).bind('ajax_list_change',function(){
                if (!updateMomentally) {
                    clearTimeout(obj.refresh_timeout);
                    obj.refresh_timeout = setTimeout(function(){
                        obj.active_page = 1;
                        obj.updateContent(true, 0);
                    },1000);
                } else {
                    obj.active_page = 1;
                    obj.updateContent(true, 0);
                }
            });
            $("."+class_name).bind('ajax_list_change_incrementally',function(){
                clearTimeout(obj.refresh_timeout);
                if (!updateMomentally) {
                    clearTimeout(obj.refresh_timeout);
                    obj.refresh_timeout = setTimeout(function(){
                        obj.active_page = 1;
                        obj.updateContent(true, 0);
                    },1000);
                } else {
                    obj.active_page = 1;
                    obj.updateContent(true, 0);
                }
            });
        }
    };

    this.updateContent = function(go_to_first_page = true, incrementally = 0) {
        let search_arr = {};
        for (let class_name in obj.search_fields_classes) {
            if(search_arr===null) {
                search_arr = {};
            }
            search_arr[class_name] = $("."+class_name).val();
        }

        if($(".myajaxlist_paginate_box_"+obj.key)!=undefined&&$(".myajaxlist_paginate_box_"+obj.key).length>0) {
            search_arr['active_page'] = obj.active_page;
        }

        let geturl = '/pioro/myajaxlist/'+this.key+'/getcontent/'+incrementally;
        if(search_arr!==null) {
            geturl += arrayToUrl(search_arr);
        }

        if(go_to_first_page) {
            this.active_page = 1;
        }

        $.ajax({
            type: "GET",
            url: geturl,
            async: true,
            success: function (result_html) {
                if(!incrementally) {
                    $(".myajaxlist_content_" + obj.key).html(result_html.content_html);
                }
                else if(incrementally) {
                    $(".myajaxlist_content_" + obj.key).append(result_html.content_html);
                }
                $(".myajaxlist_content_"+obj.key).attr('data-pagescount',result_html.pages_count);
                if($(".myajaxlist_paginate_box_"+obj.key)!=undefined) {
                    $(".myajaxlist_paginate_box_" + obj.key).html(result_html.paginate_html);
                }

                if(obj.config.scroll_top_off==undefined||!obj.config.scroll_top_off) {
                    let top_pos = $(".myajaxlist_content_" + obj.key).offset().top - 300;
                    if ($(window).scrollTop() > top_pos) {
                        $("html, body").animate({'scroll-top': top_pos}, 300);
                    }
                }

                let loc_url = window.location.href.split("?");
                loc_url = loc_url[0];

                obj.paginationEngine();

                if(history_api_on==1) {
                    history.pushState(null, null, loc_url + arrayToUrl(search_arr));
                }

                obj.showArrowEngine();

                $(".myajaxlist_content_" + obj.key).trigger('update');
            }
        });
    };

    this.showArrowEngine = function() {
        if(obj.config.special_paggination_left_arr_class!=undefined&&obj.config.special_paggination_left_arr_class!='') {
            let class_name = ("." + obj.config.special_paggination_left_arr_class).replace("..",".");
            if(obj.active_page==1||obj.pages_count<=1)
                $(class_name).hide();
            else
                $(class_name).show();
        }
        if(obj.config.special_paggination_right_arr_class!=undefined&&obj.config.special_paggination_right_arr_class!='') {
            let class_name = ("."+obj.config.special_paggination_right_arr_class).replace("..",".");
            if(obj.active_page==obj.pages_count||obj.pages_count<=1)
                $(class_name).hide();
            else
                $(class_name).show();
        }
    }

    this.paginationEngine = function() {
        if($(".myajaxlist_paginate_box_"+obj.key)!=undefined) {

            obj.pages_count = $(".myajaxlist_content_" + obj.key).attr("data-pagescount");

            if(obj.pages_count<=1) {
                $(".myajaxlist_paginate_box_"+obj.key).hide();
            }
            else {
                $(".myajaxlist_paginate_box_"+obj.key).show();
            }

            $(".myajaxlist_paginate_box_"+obj.key).find('.one_page').click(function(){
                if(!$(this).hasClass('active')) {
                    obj.active_page = $(this).attr('data-page');
                    obj.updateContent(false);
                }
            });

            $(".myajaxlist_paginate_box_"+obj.key).find('.left_button').click(function(){
                leftPage();
            });

            $(".myajaxlist_paginate_box_"+obj.key).find('.right_button').click(function(){
                rightPage();
            });
        }

        if(obj.config.special_paggination_left_arr_class!=undefined&&obj.config.special_paggination_left_arr_class!='') {
            let class_name = ("."+obj.config.special_paggination_left_arr_class).replace("..",".");
            $(class_name).unbind('click').click(function(){
                leftPage();
            });
        }

        if(obj.config.special_paggination_right_arr_class!=undefined&&obj.config.special_paggination_right_arr_class!='') {
            let class_name = ("."+obj.config.special_paggination_right_arr_class).replace("..",".");
            $(class_name).unbind('click').click(function() {
                rightPage();
            });
        }

        function leftPage() {
            if(obj.active_page>1) {
                obj.active_page--;
                obj.updateContent(false);
            }
        }

        function rightPage() {
            let all_pages = $(".myajaxlist_content_" + obj.key).attr("data-pagescount");
            if(obj.active_page<(all_pages*1/1)) {
                obj.active_page++;
                obj.updateContent(false);
            }
        }
    };

    obj.init();

}
