

function _newCookiePolicy(html, forceShow = false) {

    let cookie_name = 'cookiepolicyinfo_new';
    let google_consents_name = 'google_consents';
    let cookie_analytics_name = 'cookie_analytics';
    let cookie_marketing_name = 'cookie_marketing';

    if(!getCookie(cookie_name) || !getCookie(cookie_name) || forceShow) {

        $(".page").append(html.html);

        let obj = this;

        let HTML_box = $(".js-cp-box");
        let HTML_general_box = $(HTML_box).find(".js-cp-general-box");
        let HTML_settings_box = $(HTML_box).find(".js-cp-settings-box");
        let HTML_settings_button = $(HTML_box).find(".js-cp-settings-button");
        let HTML_save_button = $(HTML_box).find(".js-cp-save-button");
        let HTML_reject_all_button = $(HTML_box).find(".js-cp-reject-all-button");
        let HTML_cancel_all_button = $(HTML_box).find(".js-cp-cancel-all-button");
        let HTML_accept_all_button = $(HTML_box).find(".js-cp-accept-all-button");
        let HTML_accept_selected_button = $(HTML_box).find(".js-cp-accept-selected-button");
        let HTML_show_cookie_settings = $(".js-cp-show-cookie-setting");
        let HTML_settings_cookies = $(HTML_box).find(".new-cp-content-settings-options-one-cookie");

        this.init = function () {

            $(HTML_settings_box).hide();

            $(HTML_settings_button).click(function () {
                $(HTML_settings_box).slideDown(300);
                $(HTML_general_box).slideUp(300);
            });

            $(HTML_save_button).click(function () {
                $(HTML_settings_box).slideUp(300);
                $(HTML_general_box).slideDown(300);
            });

            $(HTML_cancel_all_button).click(function () {
                setCookie(cookie_name, true, 365);
                $(HTML_box).slideUp(300, function () {
                    $(HTML_box).remove();
                });
                window.location.reload(true);
            });

            $(HTML_accept_all_button).click(function () {
                setCookie(cookie_name, true, 365);
                let google_consents ={
                    'ad_storage': 'granted',
                    'ad_user_data': 'granted',
                    'ad_personalization': 'granted',
                    'analytics_storage': 'granted'
                };

                gtag('consent', 'update',google_consents);

                setCookie(google_consents_name, JSON.stringify(google_consents), 365);
                setCookie(cookie_analytics_name, true, 365);
                setCookie(cookie_marketing_name, true, 365);

                $(HTML_box).slideUp(300, function () {
                    $(HTML_box).remove();
                });
                window.location.reload(true);
            });

            $(HTML_reject_all_button).click(function(){
                setCookie(cookie_name,true);
                let google_consents = {
                    'ad_storage': 'denied',
                    'ad_user_data': 'denied',
                    'ad_personalization': 'denied',
                    'analytics_storage': 'denied'
                };

                gtag('consent', 'update',google_consents);

                setCookie(google_consents_name, JSON.stringify(google_consents), 365);
                setCookie(google_consents_name,{},-1);
                setCookie(cookie_analytics_name,true,-1);
                setCookie(cookie_marketing_name,true,-1);

                $(HTML_box).slideUp(300,function(){
                    $(HTML_box).remove();
                });
                window.location.reload(true);
            });

            $(HTML_accept_selected_button).click(function () {
                let google_consents = {
                    'ad_storage': 'denied',
                    'ad_user_data': 'denied',
                    'ad_personalization': 'denied',
                    'analytics_storage': 'denied'
                };

                const cookie_analytics_box = $('[data-cookie="cookie_analytics"]')
                if (!$(cookie_analytics_box).hasClass('disabled')) {
                    if ($(cookie_analytics_box).hasClass('on')) {
                        setCookie(cookie_analytics_name, true, 365);
                        google_consents.analytics_storage = 'granted';
                    } else {
                        setCookie(cookie_analytics_name, true, -1);
                        google_consents.analytics_storage = 'denied';
                    }
                }
                const cookie_marketing_box = $('[data-cookie="marketing"]')
                if (!$(cookie_marketing_box).hasClass('disabled')) {
                    if ($(cookie_marketing_box).hasClass('on')) {
                        setCookie(cookie_marketing_name, true, 365);
                        google_consents.ad_storage = 'granted';
                        google_consents.ad_user_data = 'granted';
                        google_consents.ad_personalization = 'granted';
                    } else {
                        setCookie(cookie_marketing_name, true, -1);
                        google_consents.ad_storage = 'denied';
                        google_consents.ad_user_data = 'denied';
                        google_consents.ad_personalization = 'denied';
                    }
                }

                gtag('consent', 'update',google_consents);

                setCookie(google_consents_name, JSON.stringify(google_consents), 365);
                setCookie(cookie_name, true, 365);

                $(HTML_box).slideUp(300, function () {
                    $(HTML_box).remove();
                });
                window.location.reload(true);
            });

            $(HTML_settings_cookies).on('click keypress', function (e) {
                if (e.which === 13 || e.type === 'click') {
                    if (!$(this).hasClass('disabled')) {
                        if ($(this).hasClass("on")) {
                            $(this).removeClass("on");
                            $(this).find(".new-cp-content-settings-options-one-cookie-checkbox").attr('aria-label', false);
                        } else {
                            $(this).addClass("on");
                            $(this).find(".new-cp-content-settings-options-one-cookie-checkbox").attr('aria-label', true);
                        }
                    }
                }
            });

            if (HTML_show_cookie_settings.length > 0) {
                $(HTML_show_cookie_settings).click(function (e) {
                    $(HTML_box).show();
                    $(".new-cp-content-settings-options-one-cookie").each(function () {
                        if (!$(this).hasClass('disabled')) {
                            let special_cookie_name = $(this).attr('data-cookie');
                            if (getCookie(special_cookie_name)) {
                                $('div[data-cookie=' + special_cookie_name + ']').addClass('on');
                            }
                        }
                    });
                });
            }
        }

        obj.init();
    }

}
