function _pageContrast() {
    let obj = this;

    this.on = false;
    this.button = $(".plugin_contrast_button");
    this.auto = false;

    // this.search_tags = ['body','section','header','h1','h2','h3','h4','h5','h6', 'div', 'p', 'a', 'span', 'strong', 'em', 'ul', 'ol', 'li'];
    this.search_tags = ['div','section'];
    // this.search_tags = ['a'];

    this.init = function() {
        if($(obj.button)!=undefined) {
            obj.prepareAuto();
            obj.default();
            obj.changeEvent();
        }
    };

    this.default = function() {
        let val = getCookie('plugin_contrast');
        if(val==1) {
            obj.setOn();
        }
    };

    this.changeEvent = function() {
        $(obj.button).click(function(){
            if(!obj.on) {
                obj.setOn();
            }
            else {
                obj.setOff();
            }
            return false;
        });
    };

    this.setOn = function() {
        $(obj.button).addClass("on");
        $("body").addClass("page_contrast");
        setCookie('plugin_contrast',1);
        obj.on = true;
        obj.setAuto();
    };

    this.setOff = function() {
        $(obj.button).removeClass("on");
        $("body").removeClass("page_contrast");
        setCookie('plugin_contrast',0);
        obj.on = false;
        obj.setAuto();
        window.location.reload(true);
    };

    this.prepareAuto = function() {
        obj.auto = $(obj.button).attr('data-a');
        if(obj.auto) {
            $.each(obj.search_tags, function (ind, v) {
                $(v).each(function () {
                    let tag = $(this);
                    tag.data('normal_bg', tag.css('background-color'));
                    tag.data('normal_color', tag.css('color'));
                });
            });
        }
    };

    this.setAuto = function() {
        if(obj.auto) {
            $(obj.search_tags.join(',')).each(function(){
                let tag = $(this);
                if(tag[0].tagName=='A') {
                    obj.on ? tag.css('color', 'yellow') : tag.css('color', tag.data('normal_color'));
                }
                else {
                    obj.on ? tag.css('color', '#fff') : tag.css('color', tag.data('normal_color'));
                    obj.on ? tag.css('background-color', 'transparent') : tag.css('background-color', tag.data('normal_bg'));
                }
            });
        }
    };

    obj.init();
}

function _fontSizePlugin() {

    let obj = this;

    this.font_size = 100;
    this.search_tags = ['h1','h2','h3','h4','h5','h6', 'div', 'p', 'a', 'span', 'strong', 'em', 'ul', 'ol', 'li'];

    this.HTML_box = $(".plugin_font_sizes");
    if(obj.HTML_box!=undefined) {
        this.HTML_buttons = $(obj.HTML_box).find(".font_button");
        // this.HTML_large_font_button = $(obj.HTML_box).find(".font_large");
        // this.HTML_normal_font_button = $(obj.HTML_box).find(".font_normal");
        // this.HTML_small_font_button = $(obj.HTML_box).find(".font_small");
    }

    this.init = function() {
        if(obj.HTML_box!=undefined) {
            obj.prepareTags();
            obj.setDefault();
            obj.clickButtonEvents();
        }
    };

    this.clickButtonEvents = function() {
        $(obj.HTML_buttons).click(function(){
            obj.font_size = $(this).attr('data-font-size');
            obj.setFontSize();
        });
    };

    this.prepareTags = function() {
        $.each(obj.search_tags, function(ind, v){
            $(v).each(function(){
                let tag = $(this);
                let font_size = tag.css('font-size');
                let force_font_size = $(tag).data('force-font-size');

                if(force_font_size) {
                    font_size = force_font_size;
                }

                if(font_size.indexOf('%') > -1){
                    tag.data('normal_font_size', parseFloat(font_size.replace('%','')));
                    tag.data('normal_font_unit', '%');
                } else {
                    tag.data('normal_font_size', parseFloat(font_size.replace(font_size.substr(-2),'')));
                    tag.data('normal_font_unit', font_size.substr(-2));
                }
            });
        });
    };

    this.setDefault = function() {
        let val = getCookie('plugin_font_size');
        if(val) {
            obj.font_size = val;
            obj.setFontSizeEngine();
        }
    };

    this.setFontSize = function() {
        setCookie('plugin_font_size',obj.font_size);
        obj.setFontSizeEngine();
    };

    this.setFontSizeEngine = function() {
        $.each(obj.search_tags, function(ind, v){
            $(v).each(function(){
                let tag = $(this);
                tag.css('font-size', (tag.data('normal_font_size')*(obj.font_size/100))+tag.data('normal_font_unit'));
            });
        });
    };

    obj.init();

}
