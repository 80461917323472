function carouselPlugin(
    DOM_class,
    max_move_elements_count,
    auto_move_time_in_ms,
    disable_loop = false, // disable loop(hide or disable btn when last element)
    elements_in_row = 4, // visible elements in row
    disable_loop_disable_arrows = false // true - disable btn | false - hide btn(default)
) {
    function _engine(carousel_dom) {

        var obj = this;

        this.HTML_box = carousel_dom;
        this.HTML_arr_left = $(obj.HTML_box).find(".carousel_arrow_left");
        this.HTML_arr_right = $(obj.HTML_box).find(".carousel_arrow_right");
        this.HTML_elements_parent = $(obj.HTML_box).find(".carousel_elements_parent");
        this.HTML_play_button = $(obj.HTML_box).find(".carousel_play_btn");
        this.HTML_pause_button = $(obj.HTML_box).find(".carousel_pause_btn");

        this.move_elements_count = max_move_elements_count;
        this.move_block = 0;

        this.disable_loop = disable_loop;
        this.disable_loop_disable_arrows = disable_loop_disable_arrows;
        this.elements_in_row = elements_in_row;
        this.current_first_elem = null;

        if (this.disable_loop) {
            $(obj.HTML_box).find('.carousel_elements').first().addClass('first_element');
            $(obj.HTML_box).find('.carousel_elements').last().addClass('last_element');
        }

        this.move_on = true;
        this.play = false;

        this.touch_clicked = 0;
        this.touch_start = 0;

        this.auto_move_timeout = null;
        this.auto_move_time_in_ms = 1000;

        this.init = function() {
            obj.prepareHtml();
            obj.prepareCss();
            obj.checkCanBeMove();
            obj.arrayEvents();
            obj.pausePlayEvent();
            obj.autoMoveTimer();

            if (obj.disable_loop) {
                if (obj.disable_loop_disable_arrows) {
                    $(obj.HTML_arr_left).attr('disabled', true).addClass('disabled');
                } else {
                    $(obj.HTML_arr_left).hide();
                }

                if (obj.HTML_elements.length < obj.elements_in_row) {
                    if (obj.disable_loop_disable_arrows) {
                        $(obj.HTML_arr_right).attr('disabled', true).addClass('disabled');
                    } else {
                        $(obj.HTML_arr_right).hide();
                    }
                }
            }

            $(window).resize(function(){
                obj.checkCanBeMove();
            });

            $(obj.HTML_box).css({
                '-ms-touch-action': 'pan-y',
                'touch-action': 'pan-y'
            });
        }

        this.isOdd = (num) => num % 2;

        this.disableLoopCheck = function (clicked_btn) {
            if (clicked_btn === 'left') {
                obj.current_first_elem = $(obj.HTML_elements).first();
            } else {
                obj.current_first_elem = $(obj.HTML_elements).first().next();
            }

            if ($(obj.current_first_elem).hasClass('first_element')) {
                if (obj.disable_loop_disable_arrows) {
                    $(obj.HTML_arr_left).attr('disabled', true).addClass('disabled');
                } else {
                    $(obj.HTML_arr_left).hide();
                }
            } else {
                if (obj.disable_loop_disable_arrows) {
                    $(obj.HTML_arr_left).attr('disabled', false).removeClass('disabled');
                } else {
                    $(obj.HTML_arr_left).show();
                }
            }

            if ($(obj.HTML_elements[max_move_elements_count > 1 ? (obj.isOdd(obj.HTML_elements.length) ? (obj.elements_in_row - max_move_elements_count) : ((obj.elements_in_row + 1) - max_move_elements_count)) : obj.elements_in_row]).hasClass('last_element')) {
                if (clicked_btn === 'left') {
                    if (obj.disable_loop_disable_arrows) {
                        $(obj.HTML_arr_right).attr('disabled', false).removeClass('disabled');
                    } else {
                        $(obj.HTML_arr_right).show();
                    }
                } else {
                    if (obj.disable_loop_disable_arrows) {
                        $(obj.HTML_arr_right).attr('disabled', true).addClass('disabled');
                    } else {
                        $(obj.HTML_arr_right).hide();
                    }
                }
            } else {
                if (obj.disable_loop_disable_arrows) {
                    $(obj.HTML_arr_right).attr('disabled', false).removeClass('disabled');
                } else {
                    $(obj.HTML_arr_right).show();
                }
            }
        }

        this.checkCanBeMove = function() {
            obj.move_on = false;
            let visible_elements_count = 0;
            let width = 0;
            let box_width = $(obj.HTML_elements_parent).parent().width();
            let right_padding = null;
            $(obj.HTML_elements).each(function(){
                if(right_padding===null) {
                    right_padding = $(this).outerWidth() - $(this).width();
                }
                width += $(this).outerWidth();
                if((width-right_padding)<=box_width) {
                    visible_elements_count++;
                }
                else {
                    obj.move_on = true;
                }
            });

            if(obj.move_on) {
                $(obj.HTML_arr_left).show();
                $(obj.HTML_arr_right).show();
            }
            else {
                $(obj.HTML_arr_left).hide();
                $(obj.HTML_arr_right).hide();
            }

            if(visible_elements_count<max_move_elements_count) {
                obj.move_elements_count = visible_elements_count;
            }
            else {
                obj.move_elements_count = max_move_elements_count;
            }
        }

        this.prepareCss = function() {
            $(obj.HTML_elements_parent).css({'position':'relative'});
        }

        this.prepareHtml = function() {
            this.HTML_elements = $(obj.HTML_box).find(".carousel_elements");
        }

        this.arrayEvents = function() {
            $(obj.HTML_arr_left).click(obj.turnRight);
            $(obj.HTML_arr_right).click(obj.turnLeft);

            $(obj.HTML_box).on('touchstart',function(e){
                e = e.touches[0];
                obj.touch_start = e.clientX;
                obj.touch_clicked = 1;
            });
            $(obj.HTML_box).on('mousedown',function(e){
                obj.touch_start = e.clientX;
                obj.touch_clicked = 1;
            });
            $(obj.HTML_box).on('mouseup',function(e){
                obj.touch_clicked = 0;
            });
            $(obj.HTML_box).on('touchmove',function(e){
                if(obj.touch_clicked==1) {
                    e = e.touches[0];
                    if (e.clientX < (obj.touch_start - 60)) {
                        obj.turnLeft();
                    } else if (e.clientX > (obj.touch_start + 60)) {
                        obj.turnRight();
                    }
                }
            });
            $(obj.HTML_box).on('mousemove',function(e){
                if(obj.touch_clicked==1) {
                    if (e.clientX < (obj.touch_start - 60)) {
                        obj.turnLeft();
                    } else if (e.clientX > (obj.touch_start + 60)) {
                        obj.turnRight();
                    }
                }
            });
        }

        this.pausePlayEvent = function() {
            obj.pausePlayButtonEngine();
            if(obj.HTML_pause_button.length>0) {
                $(obj.HTML_pause_button).click(function(){
                    obj.play = false;
                    obj.pausePlayButtonEngine();
                });
                $(obj.HTML_play_button).click(function(){
                    obj.play = true;
                    obj.pausePlayButtonEngine();
                });
            }
        }

        this.pausePlayButtonEngine = function() {
            if(obj.HTML_pause_button.length>0) {
                if(obj.play) {
                    $(obj.HTML_pause_button).show();
                    $(obj.HTML_play_button).hide();
                }
                else {
                    $(obj.HTML_pause_button).hide();
                    $(obj.HTML_play_button).show();
                }
            }
        }

        this.turnLeft = function() {
            if(obj.move_block==0&&obj.move_on) {
                obj.move_block = 1;
                clearTimeout(obj.auto_move_timeout);
                let width = 0;
                let tmp_obj = [];
                for(let k in obj.HTML_elements) {
                    if(k<obj.move_elements_count) {
                        let el = obj.HTML_elements[k];
                        width += $(el).outerWidth();
                        $(obj.HTML_elements_parent).append(el);
                        let el_copy = $(el).clone();
                        tmp_obj.push(el_copy);
                    }
                }
                tmp_obj.reverse();
                for(let k in tmp_obj) {
                    $(obj.HTML_elements_parent).prepend(tmp_obj[k]);
                }
                $(".page .tooltip_content").removeClass('active');
                $(obj.HTML_elements_parent).animate({'left':(-1*width)+"px"},500,function(){
                    $(obj.HTML_elements_parent).css({'left':0});
                    for(let k in tmp_obj) {
                        $(tmp_obj[k]).remove();
                    }
                    obj.prepareHtml();
                    obj.move_block = 0;
                    obj.touch_clicked = 0;
                    obj.autoMoveTimer();
                    obj.disableLoopCheck('right');
                });
            }
        }

        this.turnRight = function() {
            if(obj.move_block==0&&obj.move_on) {
                obj.move_block = 1;
                clearTimeout(obj.auto_move_timeout);
                let width = 0;
                let tmp_obj = [];
                let all_elements = obj.HTML_elements.length;
                let html_elements = [];
                for(let k in obj.HTML_elements) {
                    if(k>=(all_elements-obj.move_elements_count)) {
                        let el = obj.HTML_elements[k];
                        width += $(el).outerWidth();
                        html_elements.push(el);
                        let el_copy = $(el).clone();
                        tmp_obj.push(el_copy);
                    }
                }
                for (let k in html_elements.reverse()) {
                    $(obj.HTML_elements_parent).prepend(html_elements[k]);
                }
                for(let k in tmp_obj) {
                    $(obj.HTML_elements_parent).append(tmp_obj[k]);
                }

                $(".page .tooltip_content").removeClass('active');
                $(obj.HTML_elements_parent).css({'left':(-1*width)+"px"});
                $(obj.HTML_elements_parent).animate({'left':0},500,function(){
                    for(let k in tmp_obj) {
                        $(tmp_obj[k]).remove();
                    }
                    obj.prepareHtml();
                    obj.move_block = 0;
                    obj.touch_clicked = 0;
                    obj.autoMoveTimer();
                    obj.disableLoopCheck('left');
                });
            }
        }

        this.autoMoveTimer = function() {
            if(!isNaN(obj.auto_move_time_in_ms)&&obj.auto_move_time_in_ms>0) {
                obj.auto_move_timeout = setTimeout(function(){
                    if(obj.play) {
                        obj.turnRight();
                    }
                    else {
                        obj.autoMoveTimer();
                    }
                }, obj.auto_move_time_in_ms);
            }
        }

        obj.init();
    }

    $("."+DOM_class).each(function(){
        new _engine($(this));
    });
}
