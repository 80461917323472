function home() {
    $(document).ready(
        function () {
            var kar_box = $(".carousel_content > ul");
            var kar_lewo = $(".carousel_arrs .arr_left img");
            var kar_prawo = $(".carousel_arrs .arr_right img");
            var list = $(".carousel_content > ul > li");
            var list_size = list.length;
            var czas_przesun = 1000;
            var width = window.innerWidth;
            if(list_size>3) {
                if(list_size>4) {
                    if(width > 1199) {
                        new carousel(kar_box, kar_lewo, kar_prawo, czas_przesun, 33.9);
                    }else if(width > 991){
                        new carousel(kar_box, kar_lewo, kar_prawo, czas_przesun, 52);
                    }
                    else{
                        new carousel(kar_box, kar_lewo, kar_prawo, czas_przesun, 103.9);
                    }
                }
            }
        }
    );
}

function home2() {
    $(document).ready(
        function () {
            var kar_box = $("section.last_news div.news_carousel ul");
            var kar_lewo = $("section.last_news .arr_left img");
            var kar_prawo = $("section.last_news .arr_right img");
            var list = $("section.last_news div.news_carousel ul li");
            var list_size = list.length;
            var czas_przesun = 1000;
            var width = window.innerWidth;
            if(list_size>3) {
                if(width > 1199) {
                    new carousel(kar_box, kar_lewo, kar_prawo, czas_przesun, 33.9);
                }else if(width > 991){
                    new carousel(kar_box, kar_lewo, kar_prawo, czas_przesun, 52);
                }
                else{
                    new carousel(kar_box, kar_lewo, kar_prawo, czas_przesun, 103.9);
                }
            }
        }
    );
}

function carousel(content,left,right,anim_time, proc) {

    var obj = this;
    this.left = left;
    this.right = right;
    this.content = content;
    this.anim_time = anim_time;
    this.anim_block = 0;
    this.proc = proc;

    this.interval = false;

    this.init = function() {
        obj.arr_click();

        obj.setTimeout();
    };

    this.arr_click = function() {
        $(obj.left).click(
            function() {
                obj.turn_left();
            }
        );

        $(obj.right).click(
            function() {
                obj.turn_right();
            }
        );
    };

    this.turn_left = function() {
        if(obj.anim_block==0) {

            obj.setTimeout();

            obj.anim_block = 1;
            var child = obj.getChildren();
            var first = $(child).eq(0);
            $(content).append($(first).clone());
            $(content).animate({'left': '-' + obj.proc + "%"}, anim_time, function () {
                $(content).css({'left':0});
                $(first).remove();
                obj.anim_block = 0;
            });
        }
    };

    this.turn_right = function() {
        if(obj.anim_block==0) {

            obj.setTimeout();

            obj.anim_block = 1;
            var child = obj.getChildren();
            var last = $(child).eq($(child).size()-1);
            $(content).prepend($(last).clone()).css({'left':'-'+obj.proc+'%'});
            $(content).animate({'left': '0'}, anim_time, function () {
                $(last).remove();
                obj.anim_block = 0;
            });
        }
    };

    this.getChildren = function() {
        return $(obj.content).children('li');
    };

    this.setTimeout = function() {
        clearTimeout(obj.interval);
        obj.interval = setTimeout(function(){obj.turn_right();},(2000+obj.anim_time));
    };

    obj.init();
}
