// nacisniecie na enter event
$.fn.enterKey = function (fnc) {
    return this.each(function () {
        $(this).keypress(function (ev) {
            if ((ev.keyCode ? ev.keyCode : ev.which) === 13) {
                fnc.call(this, ev);
            }
        })
    })
}
