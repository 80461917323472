$(document).ready(
    function (){
        $('.more_info_input').closest('.row').addClass('border_row')
        $('.border_row').append('<div class="border_div"></div>')
        $('.more_info_input_top').click(
            function (){
                console.log('a')
                if(!$(this).next().is(':visible')){
                    $(this).next().fadeIn(0);
                    $(this).addClass('active');
                    $(this).parent().addClass('active_parent')
                    $(this).attr('aria-expanded','true');
                    $(this).next().attr('aria-hidden','false');
                }else{
                    $(this).next().fadeOut(0);
                    $(this).removeClass('active');
                    $(this).parent().removeClass('active_parent')
                    $(this).attr('aria-expanded','false');
                    $(this).next().attr('aria-hidden','true');
                }
            }
        );

        more_btn_to_bottom()

        $('.switcher').click(
            function (){
                $(this).toggleClass('active')
            }
        );

        $('.chat_inner_box').each(
            function (){
                if($(this).find('.default_font').outerHeight() >= 51){
                    $(this).addClass('large');
                }
            }
        );

        show_password();
        popups();
        ticket_counter()
        $('.default_select').select2({ minimumResultsForSearch: -1 });

        $('.rwd_btn').click(
            function (){
                if(!$('.page_header .header_menu_holder').is(':visible')){
                    $('.page_header .header_menu_holder').fadeIn(300);
                    $(this).addClass('active');
                }else{
                    $('.page_header .header_menu_holder').fadeOut(300);
                    $(this).removeClass('active');
                }
            }
        );
        events_date();

        entry_form();
    }
);

function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

function _showPopupConfirm(title, description, confirm_btn_name, success_or_delete_or_question_or_fail, confirm_func) {

    this.addClassForSuccess = function() {
        $(popup_html).find(".info_header").addClass('success_header');
        $(popup_html).find(".js_popup_confirm").addClass('success_btn');
    }

    this.addClassForDelete = function() {
        $(popup_html).find(".info_header").addClass('delete');
        $(popup_html).find(".js_popup_confirm").addClass('deleter_btn');
    }

    this.addClassForQuestion = function() {
        $(popup_html).find(".info_header").addClass('question');
        $(popup_html).find(".js_popup_confirm").addClass('success_btn');
    }

    this.addClassForFail = function() {
        $(popup_html).find(".with_icon").addClass('fail_icon');
        $(popup_html).find(".js_popup_confirm").addClass('success_btn');
    }

    this.removeClass = function() {
        $(popup_html).find(".info_header").removeClass('success_header');
        $(popup_html).find(".info_header").removeClass('delete');
        $(popup_html).find(".js_popup_confirm").removeClass('success_btn');
        $(popup_html).find(".js_popup_confirm").removeClass('deleter_btn');
    }

    var popup = this;


    var popup_html = $(".custom_popup");
    $(popup_html).find(".js_popup_title").html("<h3><strong>" + title + "</strong></h3>");
    if(description=='') {
        $(popup_html).find(".js_popup_description").parent().html('').fadeOut(0);
    }
    else {
        $(popup_html).find(".js_popup_description").html("<p>" + description + "</p>").fadeIn(0);
    }
    $(popup_html).find(".js_popup_confirm").html(confirm_btn_name);
    $(popup_html).find(".js_popup_confirm").unbind('click').click(function(){
        if(confirm_func!=undefined) {
            confirm_func();
        }
        popup.close();
    });
    $(popup_html).find('.js_close_popup').unbind('click').click(function(){
        popup.close();
    });
    if(success_or_delete_or_question_or_fail=='success') {
        popup.addClassForSuccess();
    }
    else if(success_or_delete_or_question_or_fail=='delete') {
        popup.addClassForDelete();
    }
    else if(success_or_delete_or_question_or_fail=='fail') {
        popup.addClassForFail();
    }
    else if(success_or_delete_or_question_or_fail=='question') {
        $('.js_popup_confirm').parent().addClass('double_btn_popup');
        const div_wrapper = $('.js_popup_confirm').parent();
        $(div_wrapper).prepend("<button class='cancel_btn close_popup_window'>Nie</button>");
        popup.addClassForQuestion();
    }
    $(popup_html).fadeIn(400);

    this.close = function() {
        $(popup_html).fadeOut(400, function(){
            popup.removeClass();
        });
    }
}

function events_date(){
    $('.event_item').each(
        function (){
            var $div = $(this).find('.date2 .month');
            var divWords = $div.text().split(/\s+/);
            $div.empty();
            $.each(divWords, function(i,w){
                $('<span/>').text(w).appendTo($div);
            });
            $(this).find('.date2 .month').text(function(){
                return $(this).text().substring(0,3);
            });
        }
    );
}

function sides(){
    window_scale();
    $(window).resize(window_scale);
    function window_scale() {
        var content_width = $('.container').outerWidth();
        var window_width = $(window).width();
        var side_width = (window_width - content_width) / 2 + 15;
        $('.side_left').css('padding-left',side_width);
        $('.side_right').css('padding-right',side_width);
    }
}

function benefit_banner_toggles(){
    $('.benefit_banner .banner_toggles button').eq(0).addClass('active')
    $('.banner_toggles_content .element_list').eq(0).fadeIn(0);
    $('.benefit_banner .banner_toggles button').click(
        function (){
            $('.benefit_banner .banner_toggles button').removeClass('active');
            $(this).addClass('active')
            $('.banner_toggles_content .element_list').stop().fadeOut(0);
            $('.banner_toggles_content .element_list').eq($(this).index()).stop().fadeIn(300);
        }
    );
}

function show_password(){
    $('.show_password').click(
        function (){
            $(this).toggleClass('password_visible');
            if($(this).hasClass('password_visible')){
                $(this).parent().prev().find('input').prop('type', 'text');
            }else{
                $(this).parent().prev().find('input').prop('type', 'password');
            }
        }
    );
}

function popups(){
    $('.close_popup_window').click(
        function (){
            $('.custom_popup').fadeOut(300);
        }
    );
}

function you_are_toggles(){
    $('.you_are .you_are_btn').eq(0).addClass('active')
    $('.you_are_content_holder .you_are_content').eq(0).fadeIn(0);
    $('.you_are_toggles').append('<div class="you_are_active_box"></div>');
    $('.you_are_active_box').css({
        'width':$('.btn_content_holder').width(),
        'left':$('.you_are_btn1.active .btn_content_holder').position().left
    });
    $('.you_are .you_are_btn').click(
        function (){
            $('.you_are .you_are_btn').removeClass('active');
            $(this).addClass('active')
            $('.you_are_content_holder .you_are_content').stop().fadeOut(0);
            $('.you_are_content_holder .you_are_content').eq($(this).index()).stop().fadeIn(300);
            if($('.you_are_btn.active').hasClass('you_are_btn1')){
                $('.you_are_active_box').css({
                    'left':$('.you_are_btn1.active .btn_content_holder').position().left
                });
            }else{
                $('.you_are_active_box').css({
                    'left':$('.you_are_btn2.active').position().left
                });
            }

            // $('.you_are_active_box').css({
            //     'left':$('.you_are_btn1.active .btn_content_holder').position().left
            // });
        }
    );
    // $('.you_are .you_are_btn').eq(0).addClass('active')
    // $('.you_are_content_holder .you_are_content').eq(0).fadeIn(0);
    // $('.you_are .you_are_btn').click(
    //     function (){
    //         $('.you_are .you_are_btn').removeClass('active');
    //         $(this).addClass('active')
    //         $('.you_are_content_holder .you_are_content').stop().fadeOut(0);
    //         $('.you_are_content_holder .you_are_content').eq($(this).index()).stop().fadeIn(300);
    //     }
    // );
}

function methods_class(){
    $('.method_box').click(
        function (){
            $(this).toggleClass('active');
        }
    );
    $('.bank_box').click(
        function (){
            $('.bank_box').removeClass('active')
            $(this).addClass('active')
        }
    );
}

function add_new_adress(){
    if($('.add_new_adress').is(':visible')){
        $('.payment_form').fadeOut(0);
    }
    $('.add_new_adress').click(
        function (){
            if(!$('.payment_form').is(':visible')){
                $('.payment_form').slideDown(300);
                $('.adress_added').slideUp(300);
                $(this).fadeOut(300);
            }
        }
    );
    $('.disable_form').click(
        function (){
            if($('.payment_form').is(':visible')){
                $('.payment_form').slideUp(300);
                $('.adress_added').slideDown(300);
                $('.add_new_adress').fadeIn(300);
            }
        }
    );
}

function toggles(){
    $('.toggle_top').each(
        function (){
           $(this).click(
               function (){
                   if(!$(this).next().is(':visible')){
                       $(this).next().slideDown(300);
                       $(this).parent().addClass('active')
                       $(this).attr('aria-expanded','true')
                       $(this).next().attr('aria-hidden','false')
                   }else{
                       $(this).next().slideUp(300);
                       $(this).parent().removeClass('active')
                       $(this).attr('aria-expanded','false')
                       $(this).next().attr('aria-hidden','true')
                   }
               }
           );
        }
    );
}

function ticket_counter(){
    $('.btn-plus, .btn-minus').on('click', function(e) {
        const isNegative = $(e.target).closest('.btn-minus').is('.btn-minus');
        const input = $(e.target).closest('.count_input').find('input');
        if (input.is('input')) {
            input[0][isNegative ? 'stepDown' : 'stepUp']()
        }
    })
    $('.delete_item').click(
        function (){
            $(this).parent().parent().parent().remove()
        }
    );
}

function show_filters_mobile(){
    $('.mobile_filters_btn').click(
        function (){
            if(!$('.filters .selects').is(':visible')){
                $('.filters .selects').slideDown(300);
                $(this).addClass('active');
            }else {
                $('.filters .selects').slideUp(300);
                $(this).removeClass('active');
            }
        }
    );
}

function topSlider() {
    var list = $(".slider > ul > li, .news_slider > ul > li");
    var left = $('.slider .nav_arr_btn_left')
    var right = $('.slider .nav_arr_btn_right')
    var time = 8000;
    var anim_time = 1000;
    var dot =$(".news_slider .slider_dots > button");
    var list_size = list.length;
    var pause_button = $('.scripts_off_btn');
    var play_button = $('.scripts_on_btn');

    var slide_num = $('.slide_num')
    if(list_size>1) {
        new slider(list, time, anim_time, left, right, dot, pause_button, play_button);
    }else{
        $('.slider .slider_nav, .slider .stop_slider').fadeOut(0);
    }
    dot.each(
        function (){
            $(this).find(slide_num).text($(this).index()+1);
        }
    );
}

function user_panel(){
    $('.user_btn_holder > div').eq(0).find('.user_btn').addClass('active')
    $('.user_box_content_holder > div').eq($('.user_btn.active').parent().index()).fadeIn(0);
    $('.user_btn').click(
        function (){
            $('.user_btn').removeClass('active');
            $(this).addClass('active');
            $('.user_box_content_holder > div').fadeOut(0);
            $('.user_box_content_holder > div').eq($(this).parent().index()).fadeIn(300);
        }
    );

    $('.edit_box input').attr('disabled','true');

    $('.user_box .start_edit').click(
        function (){
            $('.change_mail').toggleClass('editable');
            if($('.change_mail').hasClass('editable')){
                $('.edit_box input').removeAttr('disabled');
                $('.show_new').slideDown(300);
            }else{
                $('.edit_box input').attr('disabled','true');
                $('.show_new').slideUp(300);
            }
        }
    );
}

function shopping_toggles(){
    $('.user_toggle_btn').each(
        function (){
            $(this).click(
                function (){
                    if(!$(this).closest('.user_toggle_box').find('.user_toggle_bottom').is(':visible')){
                        $(this).closest('.user_toggle_box').find('.user_toggle_bottom').slideDown(300);
                        $(this).addClass('active');
                    }else{
                        $(this).closest('.user_toggle_box').find('.user_toggle_bottom').slideUp(300);
                        $(this).removeClass('active');
                    }
                }
            );
        }
    );
}

function aside_toggle(){
    var aside_toggle_btn = $('button.arrow_ico');

    $('.toggle_nav_box.active').find(aside_toggle_btn).attr('aria-expanded','true')
    $('.toggle_nav_box.active').find('.menu_box').attr('aria-hidden','false')

    if($(window).width()<=991){
        $('.toggle_nav_box.active').removeClass('active')
    }

    aside_toggle_btn.each(
        function (){
            $(this).click(
                function (){
                    if(!$(this).parent().next().is(':visible')){
                        $(this).parent().next().slideDown(300);
                        $(this).closest('.toggle_nav_box').addClass('active')
                        $(this).attr('aria-expanded','true');
                        $(this).parent().next().attr('aria-hidden','false')
                    }else{
                        $(this).parent().next().slideUp(300);
                        $(this).closest('.toggle_nav_box').removeClass('active')
                        $(this).attr('aria-expanded','false')
                        $(this).parent().next().attr('aria-hidden','true')
                    }
                }
            );
        }
    );
}

function panel_tabels(){
    $(".default_table_parent table").each(function(){
        let table = $(this);

        let scroll_div = $("<div></div>");
        $(scroll_div).addClass('table_scroll');
        $(table).after(scroll_div);
        $(scroll_div).append(table);
    });
}

function more_btn_to_bottom(){
    var header_holder = $('.section_header_holder');
    var section = $('.section_header_holder').closest('section');
    var more_btn = $('.btn2')
    var btn_place = $('.btn_holder_place');

    mobile_res();
    $(window).resize(mobile_res);
    function mobile_res(){
        if($(window).width()<=767){
            header_holder.each(
                function (){
                    if($(this).find(more_btn).length > 0){
                        $(this).closest('section').append('<div class="more_btn_holder_box text-center"></div>')
                        $(this).find(more_btn).appendTo($(this).closest('section').find('.more_btn_holder_box'))
                    }
                }
            );
        }else{
            section.each(
                function (){
                    $(this).find(more_btn).appendTo($(this).closest('section').find(btn_place))
                    $(this).children('.more_btn_holder_box').remove()
                }
            );
        }
    }


}

function consentAccepted() {
    $(".switch_box").each(function () {
        if ($(this).find('input').val() == 1) {
            $(this).find(".switcher").addClass('active');
            $(this).find('.sr-only').text('aktywne')
        }
    });

    $('.switcher').click(function () {
        let inputVal = $(this).closest(".switch_box").find('input');
        if ($(this).hasClass('active')) {
            $(inputVal).val(0);
            $(this).find('.sr-only').text('nie aktywne')
        } else {
            $(inputVal).val(1);
            $(this).find('.sr-only').text('aktywne')
        }
    });
}

function entry_sides() {
    var list = $(".entry_slides > ul > li");
    var left = false;
    var right = false;
    var time = 8000;
    var anim_time = 1000;
    var dot =$(".entry_slides_dots button");
    var list_size = list.length;
    var pause_button = $('.scripts_off_btn');
    var play_button = $('.scripts_on_btn');

    var slide_num = $('.slide_num')
    if(list_size>1) {
        new slider(list, time, anim_time, left, right, dot, pause_button, play_button);
    }else{
        $('.slider .slider_nav, .slider .stop_slider').fadeOut(0);
    }
    dot.each(
        function (){
            $(this).find(slide_num).text($(this).index()+1);
        }
    );
}

function entry_form(){
    $('.err').closest('.password_box').find('.show_password').addClass('err')

    $("input[type='text']").on('change', function() {

        if (this.value=="")  {
            $(this).removeClass('filed')
        }else{
            $(this).addClass('filed')
        }

    });
    $('.default_form textarea').on('change', function() {

        if (this.value=="")  {
            $(this).closest('.default_form_textarea').removeClass('filed')
        }else{
            $(this).closest('.default_form_textarea').addClass('filed')
        }

    });

    $("input[type='password']").on('change', function() {
        if (this.value=="")  {
            $(this).closest('.password_box_top').removeClass('filed')
        }else{
            $(this).closest('.password_box_top').addClass('filed')
        }

    });
    $("input[type='password']").focusin(
        function (){
            $(this).closest('.password_box_top').addClass('focused')
        }
    )
    $("input[type='password']").focusout(
        function (){
            $(this).closest('.password_box_top').removeClass('focused')
        }
    )
    $('.default_form textarea').focusin(
        function (){
            $(this).closest('.default_form_textarea').addClass('focused')
        }
    )
    $('.default_form textarea').focusout(
        function (){
            $(this).closest('.default_form_textarea').removeClass('focused')
        }
    )
    $('.default_form input[type="text"]').focusin(
        function (){
            $(this).closest('div').addClass('focused')
        }
    )
    $('.default_form input[type="text"]').focusout(
        function (){
            $(this).closest('div').removeClass('focused')
        }
    )
}
