$(document).ready(
    function () {
        $('.arrow_ico').attr('tabindex','0');
        setTimeout(function () {
            $('.g-recaptcha iframe').each(
                function () {
                    $(this).attr('title','nie jestem robotem');
                }
            );
        },300)
        // $('.rwd_btn').click(
        //     function () {
        //         if(!$('.page_header .text-right').is(':visible')){
        //             $('.page_header .text-right').slideDown(300);
        //             $('.page_header').addClass('menu_open')
        //         }else{
        //             $('.page_header .text-right').slideUp(300);
        //             $('.page_header').removeClass('menu_open')
        //         }
        //     }
        // );
    }
);

$(function() {
    $(window).unbind('scroll');
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();
        if (scroll >= 1) {
            $(".page_header").addClass('smaller');
        } else {
            $(".page_header").removeClass("smaller");
        }
    });
});

function labels(){
    $('.holder input').each(function(){
        let inp_obj = $(this);

        if($(inp_obj).val()) {
            $(this).prev('label').addClass('shrink');
        }

        $(inp_obj).focus(
            function(){
                $(this).prev('label').addClass('shrink');
            }
        );

        $(inp_obj).focusout(
            function(){
                if($(this).val()){
                    $(this).prev('label').addClass('shrink');
                }else{
                    $(this).prev('label').removeClass('shrink');
                }
            }
        );
    });
}

function parent_menu() {
    $('.menu_box .menu > ul > li ul').parent('li').addClass('parent');
}

function side_menu_toggle() {
    $('.toggle_nav_box').each(
        function () {
            let menu_box = $(this);
            let click_arrow = $(this).find(".arrow_ico");
            /* $(click_arrow).click(function(){
                 if($(menu_box).hasClass('active')) {
                     $(menu_box).removeClass('active');
                 }
                 else {
                     $(menu_box).addClass('active');
                 }
             });*/
            $(click_arrow).on('click keypress',
                function(e){
                    if (e.which === 13 || e.type === 'click') {
                        if($(menu_box).hasClass('active')) {
                            $(menu_box).removeClass('active');
                        }
                        else {
                            $(menu_box).addClass('active');
                        }
                    }
                }
            );
        }
    );
}

function counts(){
    $('.procent p').each(function () {
        $(this).prop('Counter',0).animate({
            Counter: $(this).text()
        }, {
            duration: 2000,
            easing: 'swing',
            step: function (now) {
                $(this).text(Math.ceil(now) + '%');
            }
        });
    });
}

function lines() {
    lines_scale();
    $(window).resize(lines_scale);
    function lines_scale() {
        $('.line1').css({
            'left':'0',
            'width':$('.first_dot').offset().left
        });

        $('.line2').css({
            'left':$('.second_dot').offset().left,
            'width':'100%'
        });
        $('.join_us .arrs').css({
            'top':$('.join_us .desc').position().top
        })
    }
}

function steps() {
    // var content_width = $('.container').outerWidth();
    // var window_width = $(window).width();
    // var side_width = (window_width - content_width) / 2;
    //
    // $('.line1').css({
    //     'left':'0',
    //     'width':$('.first_dot').offset().left
    // });
    //
    // $('.line2').css({
    //     'left':$('.second_dot').offset().left,
    //     'width':'100%'
    // });
    // $('.steps_slides').css({
    //     'padding-left':$('.empty').offset().left
    // });
    //
    // $('.steps_slides').css('padding-right',side_width);
    //
    //
    // var all_step_dots = $('.steps_box .step_holder').length;
    // for (let i=0; i<all_step_dots; i++) {
    //     $('.steps_slides .descs > ul').append('<li>');
    // }
    //
    //
    // $('.steps_box .step_holder').eq(0).addClass('active');
    // $('.step_holder.active').find('.hidden_desc').clone().appendTo($('.steps_slides .descs > ul > li').eq($(this).index()))

    let lp = 0;

    let steps = $(".join_us .step_holder");
    let steps_img = $(".join_us .steps_slides .images ul li");
    let steps_desc = $(".join_us .descs > ul > li");

    let arrow_left = $(".join_us .sl_nav .arr_left");
    let arrow_right = $(".join_us .sl_nav .arr_right");

    showStep(0);
    $(arrow_left).click(function(){
        if(lp>0) {
            lp--;
            showStep(lp);
        }
    });

    $(arrow_right).click(function(){
        if(lp<3) {
            lp++;
            showStep(lp);
        }
    });

    function showStep(lp) {
        $(steps).removeClass("active");
        $(steps).eq(lp).addClass("active");
        $(steps_img).removeClass("active");
        $(steps_img).eq(lp).addClass("active");
        $(steps_desc).removeClass("active");
        $(steps_desc).eq(lp).addClass("active");
    }

}

function faq() {
    $('.faq .questions_list > ul > li').click(
        function () {
            $(this).toggleClass('active');
            if($(this).hasClass('active')){
                $(this).find('.answer').stop().slideDown(300);
            }else{
                $(this).find('.answer').stop().slideUp(300);
            }
        }
    );
}

// function BootstrapCalendarInit(){
//     $('.input_date_clear').each(function(){
//         if(!$(this).hasClass(".datapicker_run")) {
//             var startDate = null;
//             var endDate = null;
//             var disabledDate = null;
//             if ($(this).attr("start_date") != undefined)
//                 startDate = $(this).attr("start_date");
//             if ($(this).attr("stop_date") != undefined)
//                 endDate = $(this).attr("stop_date");
//             if ($(this).attr("disabled_dates") != undefined && $(this).attr("disabled_dates") != '')
//                 disabledDate = $(this).attr("disabled_dates").split(',');
//             $(this).addClass("datapicker_run").datepicker({
//                 format: "dd-mm-yyyy",
//                 todayBtn: true,
//                 clearBtn: true,
//                 language: "pl",
//                 calendarWeeks: true,
//                 autoclose: true,
//                 todayHighlight: true,
//                 startDate: startDate,
//                 endDate: endDate,
//                 datesDisabled: disabledDate
//             });
//         }
//     });
//
//     $('.input_date_time_clear').each(function(){
//         if(!$(this).hasClass(".datapicker_run")) {
//             var opt = {
//                 format: "DD-MM-YYYY HH:mm",
//                 locale: "pl",
//                 showTodayButton: true,
//                 showClear: true,
//                 autoclose: true,
//                 tooltips: {
//                     today: 'Przejdź do dnia dzisiejszego',
//                     clear: 'Wyczyść',
//                     close: 'Zamknij',
//                     selectMonth: 'Wybierz miesiąc',
//                     prevMonth: 'Poprzedni miesiąc',
//                     nextMonth: 'Następny miesiąc',
//                     selectYear: 'Wybierz rok',
//                     prevYear: 'Poprzedni rok',
//                     nextYear: 'Następny rok',
//                     selectDecade: 'Przedział lat',
//                     prevDecade: 'Poprzedni przedział',
//                     nextDecade: 'Następny przedział',
//                     prevCentury: 'Poprzedni wiek',
//                     nextCentury: 'Następny wiek',
//                     incrementHour: 'Dodaj godzinę',
//                     pickHour: 'Wybierz godzinę',
//                     decrementHour: 'Odejmij godzinę',
//                     incrementMinute: 'Dodaj minutę',
//                     pickMinute: 'Wybierz minutę',
//                     decrementMinute: 'Odejmij minutę',
//                     incrementSecond: 'Dodaj sekundę',
//                     pickSecond: 'Wybierz sekundę',
//                     decrementSecond: 'Odejmij sekundę',
//                     selectTime: 'Ustaw godzinę'
//                 },
//                 icons: {
//                     clear: 'glyphicon glyphicon-remove'
//                 }
//             };
//             if ($(this).attr("start_date") != undefined) {
//                 startDate = $(this).attr("start_date");
//                 opt.minDate = startDate;
//             }
//             if ($(this).attr("stop_date") != undefined) {
//                 endDate = $(this).attr("stop_date");
//                 opt.maxDate = endDate;
//             }
//             if ($(this).attr("disabled_dates") != undefined && $(this).attr("disabled_dates") != '') {
//                 disabledDate = $(this).attr("disabled_dates").split(',');
//                 opt.disabledDates = disabledDate;
//             }
//             let el = $(this);
//             $(el).addClass("datapicker_run").datetimepicker(opt).on("dp.change", function (e) {
//                 $(el).trigger('change');
//             });
//         }
//     });
//
//     $('.input_date').each(function(){
//         var startDate = null;
//         var endDate = null;
//         var disabledDate = null;
//         if($(this).attr("start_date")!=undefined)
//             startDate = $(this).attr("start_date");
//         if($(this).attr("stop_date")!=undefined)
//             endDate = $(this).attr("stop_date");
//         if($(this).attr("disabled_dates")!=undefined&&$(this).attr("disabled_dates")!='')
//             disabledDate = $(this).attr("disabled_dates").split(',');
//         $(this).datepicker({
//             format: "dd-mm-yyyy",
//             todayBtn: true,
//             clearBtn: false,
//             language: "pl",
//             calendarWeeks: true,
//             autoclose: true,
//             todayHighlight: true,
//             startDate: startDate,
//             endDate:endDate,
//             datesDisabled:disabledDate
//         });
//     });
//
//     $('.input_date_time').each(function(){
//         var opt = {
//             format: "DD-MM-YYYY HH:mm",
//             locale: "pl",
//             showTodayButton: true,
//             showClear: false,
//             tooltips: {
//                 today: 'Przejdź do dnia dzisiejszego',
//                 clear: 'Wyczyść',
//                 close: 'Zamknij',
//                 selectMonth: 'Wybierz miesiąc',
//                 prevMonth: 'Poprzedni miesiąc',
//                 nextMonth: 'Następny miesiąc',
//                 selectYear: 'Wybierz rok',
//                 prevYear: 'Poprzedni rok',
//                 nextYear: 'Następny rok',
//                 selectDecade: 'Przedział lat',
//                 prevDecade: 'Poprzedni przedział',
//                 nextDecade: 'Następny przedział',
//                 prevCentury: 'Poprzedni wiek',
//                 nextCentury: 'Następny wiek',
//                 incrementHour: 'Dodaj godzinę',
//                 pickHour: 'Wybierz godzinę',
//                 decrementHour:'Odejmij godzinę',
//                 incrementMinute: 'Dodaj minutę',
//                 pickMinute: 'Wybierz minutę',
//                 decrementMinute:'Odejmij minutę',
//                 incrementSecond: 'Dodaj sekundę',
//                 pickSecond: 'Wybierz sekundę',
//                 decrementSecond:'Odejmij sekundę',
//                 selectTime: 'Ustaw godzinę'
//             },
//             icons: {
//                 clear: 'glyphicon glyphicon-remove'
//             }
//         };
//         if($(this).attr("start_date")!=undefined) {
//             startDate = $(this).attr("start_date");
//             opt.minDate = startDate;
//         }
//         if($(this).attr("stop_date")!=undefined) {
//             endDate = $(this).attr("stop_date");
//             opt.maxDate = endDate;
//         }
//         if($(this).attr("disabled_dates")!=undefined&&$(this).attr("disabled_dates")!='') {
//             disabledDate = $(this).attr("disabled_dates").split(',');
//             opt.disabledDates = disabledDate;
//         }
//         $(this).datetimepicker(opt);
//     });
//
//     $('.input_date_cancel, .input_date').attr("readonly","readonly");
// }

function BootstrapCalendarInit(){
    $('.input_date_clear').each(function(){
        if(!$(this).hasClass(".datapicker_run")) {
            var startDate = null;
            var endDate = null;
            var disabledDate = null;
            if ($(this).attr("start_date") != undefined)
                startDate = $(this).attr("start_date");
            if ($(this).attr("stop_date") != undefined)
                endDate = $(this).attr("stop_date");
            if ($(this).attr("disabled_dates") != undefined && $(this).attr("disabled_dates") != '')
                disabledDate = $(this).attr("disabled_dates").split(',');
            $(this).addClass("datapicker_run").datepicker({
                format: "dd-mm-yyyy",
                todayBtn: true,
                clearBtn: true,
                language: "pl",
                calendarWeeks: true,
                autoclose: true,
                todayHighlight: true,
                startDate: startDate,
                endDate: endDate,
                datesDisabled: disabledDate
            });
        }
    });

    $('.input_date_time_clear').each(function(){
        if(!$(this).hasClass(".datapicker_run")) {
            var opt = {
                format: "DD-MM-YYYY HH:mm",
                locale: "pl",
                showTodayButton: true,
                showClear: true,
                tooltips: {
                    today: 'Przejdź do dnia dzisiejszego',
                    clear: 'Wyczyść',
                    close: 'Zamknij',
                    selectMonth: 'Wybierz miesiąc',
                    prevMonth: 'Poprzedni miesiąc',
                    nextMonth: 'Następny miesiąc',
                    selectYear: 'Wybierz rok',
                    prevYear: 'Poprzedni rok',
                    nextYear: 'Następny rok',
                    selectDecade: 'Przedział lat',
                    prevDecade: 'Poprzedni przedział',
                    nextDecade: 'Następny przedział',
                    prevCentury: 'Poprzedni wiek',
                    nextCentury: 'Następny wiek',
                    incrementHour: 'Dodaj godzinę',
                    pickHour: 'Wybierz godzinę',
                    decrementHour: 'Odejmij godzinę',
                    incrementMinute: 'Dodaj minutę',
                    pickMinute: 'Wybierz minutę',
                    decrementMinute: 'Odejmij minutę',
                    incrementSecond: 'Dodaj sekundę',
                    pickSecond: 'Wybierz sekundę',
                    decrementSecond: 'Odejmij sekundę',
                    selectTime: 'Ustaw godzinę'
                },
                icons: {
                    clear: 'glyphicon glyphicon-remove'
                }
            };
            if ($(this).attr("start_date") != undefined) {
                startDate = $(this).attr("start_date");
                opt.minDate = startDate;
            }
            if ($(this).attr("stop_date") != undefined) {
                endDate = $(this).attr("stop_date");
                opt.maxDate = endDate;
            }
            if ($(this).attr("disabled_dates") != undefined && $(this).attr("disabled_dates") != '') {
                disabledDate = $(this).attr("disabled_dates").split(',');
                opt.disabledDates = disabledDate;
            }
            let el = $(this);
            $(el).addClass("datapicker_run").datetimepicker(opt).on("dp.change", function (e) {
                $(el).trigger('change');
            });
        }
    });

    $('.input_date').each(function(){
        var startDate = null;
        var endDate = null;
        var disabledDate = null;
        if($(this).attr("start_date")!=undefined)
            startDate = $(this).attr("start_date");
        if($(this).attr("stop_date")!=undefined)
            endDate = $(this).attr("stop_date");
        if($(this).attr("disabled_dates")!=undefined&&$(this).attr("disabled_dates")!='')
            disabledDate = $(this).attr("disabled_dates").split(',');
        $(this).datepicker({
            format: "dd-mm-yyyy",
            todayBtn: true,
            clearBtn: false,
            language: "pl",
            calendarWeeks: true,
            autoclose: true,
            todayHighlight: true,
            startDate: startDate,
            endDate:endDate,
            datesDisabled:disabledDate
        });
    });

    $('.input_date_time').each(function(){
        var opt = {
            format: "DD-MM-YYYY HH:mm",
            locale: "pl",
            showTodayButton: true,
            showClear: false,
            tooltips: {
                today: 'Przejdź do dnia dzisiejszego',
                clear: 'Wyczyść',
                close: 'Zamknij',
                selectMonth: 'Wybierz miesiąc',
                prevMonth: 'Poprzedni miesiąc',
                nextMonth: 'Następny miesiąc',
                selectYear: 'Wybierz rok',
                prevYear: 'Poprzedni rok',
                nextYear: 'Następny rok',
                selectDecade: 'Przedział lat',
                prevDecade: 'Poprzedni przedział',
                nextDecade: 'Następny przedział',
                prevCentury: 'Poprzedni wiek',
                nextCentury: 'Następny wiek',
                incrementHour: 'Dodaj godzinę',
                pickHour: 'Wybierz godzinę',
                decrementHour:'Odejmij godzinę',
                incrementMinute: 'Dodaj minutę',
                pickMinute: 'Wybierz minutę',
                decrementMinute:'Odejmij minutę',
                incrementSecond: 'Dodaj sekundę',
                pickSecond: 'Wybierz sekundę',
                decrementSecond:'Odejmij sekundę',
                selectTime: 'Ustaw godzinę'
            },
            icons: {
                clear: 'glyphicon glyphicon-remove'
            }
        };
        if($(this).attr("start_date")!=undefined) {
            startDate = $(this).attr("start_date");
            opt.minDate = startDate;
        }
        if($(this).attr("stop_date")!=undefined) {
            endDate = $(this).attr("stop_date");
            opt.maxDate = endDate;
        }
        if($(this).attr("disabled_dates")!=undefined&&$(this).attr("disabled_dates")!='') {
            disabledDate = $(this).attr("disabled_dates").split(',');
            opt.disabledDates = disabledDate;
        }
        $(this).datetimepicker(opt);
    });

    $('.input_date_cancel, .input_date').attr("readonly","readonly");
}

function company_choose() {
    $(".company_choose_select").select2().change(function(){
        let val = $(this).val();
        if(val!='') {
            window.location.href = '/firma/zmien-aktywna-firme/' + val;
        }
    });
}

function section_box() {
    $(".section_box").each(function(){
        new section_box_engine($(this));
    });

    $(".section_clear_button").click(function(){
        $(".search_event_name").val("");
        setTimeout(function(){
            $(".section_box").eq(0).find(".result_input").trigger('ajax_list_change');
        },100);
        return false;
    });

    let timeout_name = null;
    $(".search_event_name").keyup(function(){
        let sobj = $(this);
        clearTimeout(timeout_name);
        timeout_name = setTimeout(function(){
            console.log(10);
            $(sobj).trigger('ajax_list_change');
        },300);
    });

    $(".search_sort_by").select2().change(function(){
        $(this).trigger('ajax_list_change');
    });

    function section_box_engine(section_box) {
        let result_input = $(section_box).find(".result_input");
        let options = $(section_box).find(".checkbox_li");
        setDefaultValues();

        $(".section_clear_button").click(function(){
            $(result_input).val("");
            setDefaultValues();
        });

        $(options).click(function(){
            if($(this).hasClass("active")) {
                $(this).removeClass("active");
                $(this).find(".yes").removeClass("on");
                $(this).find(".no").addClass("on");
            }
            else {
                $(this).addClass("active");
                $(this).find(".yes").addClass("on");
                $(this).find(".no").removeClass("on");
            }
            updateResult();
        });

        function updateResult() {
            let val = '';
            $(section_box).find(".checkbox_li.active").each(function () {
                let did = $(this).attr("data-id");
                if (val != '') {
                    val += ',';
                }
                val += did;
            });
            $(result_input).val(val).trigger('ajax_list_change');
        }

        function setDefaultValues() {
            let val_arr = $(result_input).val().split(",");
            if(val_arr.length>0) {
                $(section_box).find('.checkbox_li').removeClass("active");
                $(section_box).find('.checkbox_li .yes, .checkbox_li .no').removeClass("on");
                for(let k in val_arr) {
                    let v = val_arr[k];
                    $(section_box).find('.checkbox_li[data-id="'+v+'"]').addClass("active").find(".yes").addClass("on");
                }
            }
        }
    }
}

function setPageCookies() {
    let cookie_val = getCookie('page_contrast');
    if(cookie_val==1) {
        $("body").addClass("page_contrast");
    }
}

function contrastEvent() {
    $(".contrast").click(function(){
        if(!$("body").hasClass("page_contrast")) {
            $("body").addClass("page_contrast");
            $(".contrast").addClass("on");
            setCookie('page_contrast',1,30);
        }
        else {
            $("body").removeClass("page_contrast");
            $(".contrast").removeClass("on");
            setCookie('page_contrast',0,30);
        }
        return false;
    });
}

function _addScanUser(promotion_id, card_no, token, card_segments){

    var obj = this;

    this.HTML_one_row = null;

    this.table = $('.promotion_scan_table');
    this.row = $(this.table).children().eq(0).clone();
    $(this.table).html('');
    this.timeout_anchor = false;
    this.code = '';
    this.count = 0;

    this.card_rfid_charts = card_segments*2;

    this.promotion_id = promotion_id;
    this.token = token;

    this.full_screen_button = $(".promotion_full_screen_ico");

    this.init = function() {
        obj.prepareHtml();
        obj.barCodeEvents();
        obj.openPopup();
        obj.refreshSession();

        $('.addScanUser').click(function(event){
            obj.searchCardEngine(3750194);
        })
    }

    this.prepareHtml = function() {
        // tutaj przygotowanie html jednego wiersza
    }

    this.barCodeEvents = function() {
        $(document).keydown(function(e){
            if(!obj.timeout_anchor) {
                obj.code = '';
                obj.count = 0;
            }
            if(e.key!='Shift'&&e.key!='Enter') {
                obj.count++;
                obj.code += e.key + "";
                clearTimeout(obj.timeout_anchor);
                obj.timeout_anchor = setTimeout(function () {
                    if (obj.count == 7||obj.count == obj.card_rfid_charts||obj.count == 11) {
                        obj.searchCardEngine(obj.code);
                        obj.timeout_anchor = false;
                    }
                }, 100);
            }
        });
    }

    this.searchCardEngine = function(card_no) {
        $.post('/firma/promocje/skanuj/ajax_check_promotion',{promotion_id:this.promotion_id,card_no:card_no,_token:this.token},function(data){
            obj.prepareUserRow(data);

            $(".first_info_box").fadeOut(300, function(){
                $(".promotion_scan_table_parent").fadeIn(300);
            });
        })
            .fail(function(result){

                $(".promotion_scan_table_parent").slideDown(500);
                $(".promotion_name_box_parent").slideUp(500);

                let row = $(obj.row).clone();
                $(row).addClass('status_no').html('<td><p class="err_txt text-center">'+result.responseText+'</p></td>');
                $(row).find("td:first-child").attr('colspan',4);
                $(obj.table).prepend(row);
                $(row).css({'opacity':0}).animate({'opacity':1},500);

                setTimeout(function(){
                    $(row).fadeOut(300,function(){
                        $(row).remove();
                    });
                },60000);

                $(".first_info_box").fadeOut(300, function(){
                    $(".promotion_scan_table_parent").fadeIn(300);
                });
            });
    }

    this.prepareUserRow = function(result) {
        var row = $(this.row).clone();
        if(result.image)
            $(row).find('.user_image').prop('src',result.image);
        if(result.name)
            $(row).find('.user_name').text(result.name);
        if(result.bdate)
            $(row).find('.user_bdate').text(result.bdate);
        if(result.age)
            $(row).find('.user_age').text(result.age+' lat');
        var programs = result.programs;
        let programs_string = '';
        if(programs&&programs.length){
            for(let k in programs) {
                let program = programs[k];
                if(program.expire!=undefined) {
                    program.name += ' - '+program.expire;
                }
                programs_string += '<span class="user_program">'+program.name+'</span>';
            }
        }
        $(row).find('.user_programs_box').html(programs_string);

        if(result.error_reasons!=undefined) {
            $(row).find('.user_programs_box').append('<p class="err_txt">'+result.error_reasons+'</p>');
        }

        $(row).css({'opacity':0});
        if(result.promo_status=='has_permissions') {
            $(row).addClass('status_yes');
            $(row).find(".yes").css({'display':'block'})
        }
        else {
            $(row).addClass('status_no');
            $(row).find(".no").css({'display':'block'})
        }

        $(obj.table).prepend(row);
        $(".promotion_scan_table_parent").slideDown(500);
        $(".promotion_name_box_parent").slideUp(500);
        $(row).animate({'opacity':1},500);

        setTimeout(function(){
            $(row).fadeOut(300,function(){
                $(row).remove();
            });
        },60000);
    }

    this.openPopup = function() {
        var elem = $(".default_table_parent")[0];
        var is_full_screen = false;

        $(obj.full_screen_button).click(function(){
            if(!is_full_screen) {
                openFullscreen();
                is_full_screen = true;
            }
            else {
                closeFullscreen();
                is_full_screen = false;
            }
        });

        function openFullscreen() {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) { /* Safari */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { /* IE11 */
                elem.msRequestFullscreen();
            }
        }

        /* Close fullscreen */
        function closeFullscreen() {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) { /* Safari */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE11 */
                document.msExitFullscreen();
            }
        }
    }

    this.refreshSession = function() {
        setInterval(function(){
            $.get('/firma/promocje/skanuj/refresh-session',function(ret){});
        },120000);
    }

    obj.init();
}

